//
//
//
//
//
//
//

import upload from '@/views/fileStream/documentManage/upload'
import formTable from '../../components/formTable'
import billTable from '../../components/formTable/billTable.vue'
import fastTable from '../../components/formTable/fastTable.vue'
export default {
    components: {
        formTable,
        upload,
        fastTable,
        billTable,
    },
    props: {
        myData: {
            type: Object,
            default() {
                return {}
            },
        },
        files: {
            type: Array,
            default() {
                return []
            },
        },
        type: {
            type: String | Number,
            default: 1,
        },
    },
}
