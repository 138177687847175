//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { sysFileInfoDownload, sysFileInfoUpload, sysFileWditAccept } from '@/api/modular/system/fileManage'
import { serverUrl } from '@/mixins/config.js'
import { DEVICE_TYPE } from '@/utils/device'
export default {
  data() {
    return {
      fileName: [],
      loading: false,
      DEVICE_TYPE,
      accept: ['.doc', '.docx', '.image/*', '.pdf', '.xls', '.xlsx'],
      fileType: ['.doc', '.docx', '.pdf', '.xls', 'xlsx', '.png', '.jpg'],
    }
  },
  props: {
    fileIds: {
      type: Array,
      default() {
        return []
      },
    },
    officialId: {
      type: String,
      default: '',
    },
    isOnlyShow: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.fileName = this.fileIds
  },
  watch: {
    fileIds(n) {
      this.fileName = n
    },
  },
  methods: {
    /**
     * 上傳文件
     */
    customRequest(data) {
      const size = data.file.size / 1024 / 1024 > 100
      const fileName = data.file.name
      const fileType = ['.doc', '.docx', '.pdf', '.xls', 'xlsx', '.png', '.jpg'] // .doc,.docx,image/*,.pdf,.xls,.xlsx
      // if (!fileType.some(j => fileName.endsWith(j))) {
      //   this.$message.error('只能傳pdf、word、excel、png、jpg')
      //   return
      // }
      if (size) {
        this.$message.error('上傳失敗：文件大於100M')
        return
      }
      if (this.loading) return
      this.loading = true
      const formData = new FormData()
      formData.append('file', data.file)
      sysFileInfoUpload(formData)
        .then((res) => {
          if (res.success) {
            this.$message.success('上傳成功')
            //
            this.fileName.push({ fileName: data.file.name, fileId: res.data })
            //
            this.$emit('update:fileIds', this.fileName)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    editAcceptBill(data) {
      sysFileWditAccept({
        ...data,
        billId: this.$route.query.id || this.officialId,
      })
        .then((res) => {
          if (res.code === 200) {
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    delDom(i) {
      this.fileName.splice(i, 1)
    },
    download(record) {
      this.cardLoading = true
      sysFileInfoDownload({ id: record.fileId })
        .then((res) => {
          this.cardLoading = false
          this.editAcceptBill({ downloadStatus: 1 })
          this.downloadfile(res)
          // eslint-disable-next-line handle-callback-err
        })
        .catch(() => {
          this.cardLoading = false
          this.$message.error('下載錯誤：獲取文件流錯誤')
        })
    },
    preView(record) {
      this.editAcceptBill({ seeStatus: 1 })
      if (this.$store.state.app.device === DEVICE_TYPE.MOBILE) {
        try {
          this.editAcceptBill({ downloadStatus: 1 })
          wx.previewFile({
            url: record.fileUrl,
            name: record.fileName,
            size: Number(record.fileSizeKb) * 1024,
          })
        } catch {}
        return
      }
      if (this.getFileType(record.fileUrl)) return this.preViewFile(record.fileUrl)
      const i = record.fileUrl.indexOf('8080')
      window.open(
        `https://view.officeapps.live.com/op/view.aspx?src=http://${serverUrl}/api${record.fileUrl.substring(i + 4)}`
      )
    },
    getFileType(filename) {
      return ['.pdf', '.png', '.jpeg', '.gif', '.jpg'].some((j) => filename.endsWith(j))
    },
    preViewFile(href) {
      window.open(href, '_blank')
    },
    creatUrl(res) {
      var blob = new Blob([res.data], { type: 'application/octet-stream;charset=UTF-8' })
      var contentDisposition = res.headers['content-disposition']
      var patt = new RegExp('filename=([^;]+\\.[^\\.;]+);*')
      var result = patt.exec(contentDisposition)
      var filename = result[1]
      var href = window.URL.createObjectURL(blob) // 創建下載的鏈接
      return { filename, href }
    },
    downloadfile(res) {
      var { filename, href } = this.creatUrl(res)
      var downloadElement = document.createElement('a')
      var reg = /^["](.*)["]$/g
      downloadElement.style.display = 'none'
      downloadElement.href = href
      downloadElement.download = decodeURI(filename.replace(reg, '$1')) // 下載後文件名
      document.body.appendChild(downloadElement)
      downloadElement.click() // 點擊下載
      document.body.removeChild(downloadElement) // 下載完成移除元素
      window.URL.revokeObjectURL(href)
    },
    empty() {
      this.fileName = []
    },
  },
}
