//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import nodes from '@/views/fileStream/documentApproval/documentManage/nodes.vue'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import fileDownAndPreView from '@/views/fileStream/documentSend/components/fileDownAndPreView.vue'
import showEditor from '@/views/fileStream/documentSend/components/showEditor.vue'
import moment from 'moment'
import mixin from '../minxin'
export default {
    mixins: [mixin],
    props: {
        myData: {
            type: Object,
            default() {
                return {}
            },
        },
    },
    components: {
        OpenData,
        nodes,
        fileDownAndPreView,
        showEditor,
    },
    data() {
        return {
            dom: [],
        }
    },
    computed: {
        list() {
            let listCopy = []
            if (this.myData.nodes && this.myData.nodes.length > 0) {
                listCopy = this.myData.nodes.slice()
                listCopy = listCopy.filter((node) => node.sort !== -1)
            }
            // let lC = this.myData.documentNodeList.slice()
            // lC = lC.filter(node => node.sort !== -1)
            return listCopy
        },
    },
    methods: {
        Filter(s) {
            // eslint-disable-next-line eqeqeq
            const values = this.flow_secrecy_degree.filter((item) => item.code == s)
            if (values.length > 0) {
                return values[0].value
            }
        },
        formatApprovalTime(approvalTime) {
            if (!approvalTime) return ''
            approvalTime = approvalTime.replace(/-/g, '/')
            const date = new Date(approvalTime)
            const dateStr = date.toLocaleString()
            return dateStr.replace(/\//g, '-')
        },
        formatListMess(node) {
            const arr = []
            if (node.deptInfos && node.deptInfos.length > 0) {
                node.deptInfos.forEach((dep) => {
                    dep.deptUsers.forEach((k) => {
                        arr.push(k)
                    })
                })
            }
            node.nodes = [...node.nodes, ...arr].filter((j) => j.userId && j.approvalFlag !== 2)
        },
        formatList(list) {
            const listCopy = JSON.parse(JSON.stringify(list))
            listCopy.forEach((j) => {
                this.formatListMess(j)
            })
            return listCopy.filter((j) => j.sort != -1 && ![99, 100].includes(j.sort))
        },
        formatJjcd(jjcd) {
            return this.flow_urgency_degree.find((j) => j.code === jjcd)?.value
        },
        formatTdtt(swsj) {
            if (!swsj) return ''
            return moment(swsj).format('YYYY-MM-DD')
        },
        formatXzfn(xzfn) {
            return this.flow_file_type.find((j) => j.code === xzfn)?.value
        },
    },
}
